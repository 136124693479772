import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAuth } from '~/helpers/store/auth';
import { Role } from '~/pages/utils/router';

function ImAuth({ rolesAuthorize }: { rolesAuthorize: Role[] }) {
  const { imLoggedAndValid, payload } = useAuth();
  const navigate = useNavigate();

  useEffect(() => {
    const myRole = payload?.roles;

    // If no roles are required, everyone can access
    if (rolesAuthorize.length === 0) {
      return;
    }

    // If my role is not in the list of roles authorized, i redirect to denied page
    if (!rolesAuthorize.includes(myRole as Role)) {
      navigate('/denied');
    }

    // If im not logged or my token is not valid i redirect to login page
    if (!imLoggedAndValid() || !myRole) {
      navigate('/');
    }
  }, []);
  return <></>;
}

export default ImAuth;
