import { create } from 'zustand';
import { persist } from 'zustand/middleware';

type TuteurState = {
  tuteur: string | null;
  setTuteur: (tuteur: string | null) => void;
};

export const useTuteur = create<TuteurState>()(
  persist(
    (set) => ({
      tuteur: null,
      setTuteur: (tuteur: string | null) => {
        set({
          tuteur,
        });
      },
    }),
    {
      name: 'tuteur-storage',
    }
  )
);
