function Files() {
  return (
    <div style={{ paddingTop: ' 25px' }}>
      
    </div>
  );
}

export default Files;

