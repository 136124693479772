import Background from '@assets/images/background-hub.png';
import styled from 'styled-components';
import CardBack from '@assets/images/card-back.png';
import Camera from '@assets/images/icon-camera.png';
import Click from '@assets/images/click.png';
import BackgroundChallenge from '@assets/images/bg-challenges.png';
import BronzeIcon from '@assets/images/icon-bronze.png'
import SilverIcon from '@assets/images/icon-argent.png'
import GoldIcon from '@assets/images/icon-or.png'
import PlatineIcon from '@assets/images/icon-platine.png'
import BronzeBg from '@assets/images/fond-score-bronze.png'
import SilverBg from '@assets/images/fond-score-argent.png'
import GoldBg from '@assets/images/fond-score-or.png'
import PlatineBg from '@assets/images/fond-score-platine.png'
import { SText } from '~/utils/ui/utils';
import { useLingui } from '@lingui/react';
import { useUser } from '~/helpers/store/user';
import { useTracking } from '~/helpers/store/tracking';
import { useEffect, useState } from 'react';
import { ChallengeDifficulty, ChallengeStatus } from '@zeus/index';
import { StoredTracking } from '~/gql/tracking/tracking';
import { resolveImg } from '~/utils/imgResolver';
import { Modal } from 'antd';
import ChallengeModal from '~/pages/authentified/components/ChallengeModal';
import ChallengePanel from '~/pages/authentified/components/ChallengePanel';

export const resolveIcon = (status: ChallengeStatus) => {
    switch (status) {
        case ChallengeStatus.BRONZE:
            return BronzeIcon;
        case ChallengeStatus.SILVER:
            return SilverIcon;
        case ChallengeStatus.GOLD:
            return GoldIcon;
        case ChallengeStatus.PLATINUM:
            return PlatineIcon;
    }
}

export const resolveBg = (status: ChallengeStatus) => {
    switch (status) {
        case ChallengeStatus.BRONZE:
            return BronzeBg;
        case ChallengeStatus.SILVER:
            return SilverBg;
        case ChallengeStatus.GOLD:
            return GoldBg;
        case ChallengeStatus.PLATINUM:
            return PlatineBg;
    }
}

export const resolveColor = (status: ChallengeStatus) => {
    switch (status) {
        case ChallengeStatus.BRONZE:
            return '#B38261';
        case ChallengeStatus.SILVER:
            return '#838EA1';
        case ChallengeStatus.GOLD:
            return '#DF781B';
        case ChallengeStatus.PLATINUM:
            return '#9F6FFF';
    }
}


interface IProps {
    setCardDraw: (value: boolean) => void;
}

function Hub ({ setCardDraw }: IProps) {
    const { i18n } = useLingui();
    const  [_trackings, _setTrackings] = useState<(StoredTracking | null)[]>([])
    const  [trackingId, setTrackingId] = useState<(number | null)>(null)
    const { user, setUser } = useUser()
    const { trackings } = useTracking()
    const [open, setOpen] = useState(false);
    const [openPanel, setOpenPanel] = useState(false);


    const openNextChallenge = () => {
        if(trackings.length < 3) {
            setCardDraw(true);
        } else {
            setOpen(true);
        }
    }

    useEffect(() => {
        const newTrackings = [];
    
        for (let elem = 0; elem < 3; elem++) {
            if(trackings[elem] && trackings[elem].challenge) {
                newTrackings.push(trackings[elem]);
            } else {
                newTrackings.push(null);
            }
        }
        _setTrackings(newTrackings);
    }, [trackings]);  

    return (
    <Container>
        <Card onClick={openNextChallenge}>
            <img src={CardBack} alt="card-back" />
            <HelpBox>
                <img src={Click} alt="click" width="80px"/>
                <SText $fontsize='20px' $margin='0 0 15px' $textShadow='2px 2px 0px #000;' $color='#fff'>{i18n._('common.next-challenge-message')}</SText>
            </HelpBox>
            <SText $fontFamily='Bangers' $fontsize='32px' $textShadow='2px 2px 0px #FFF;'>{i18n._('common.next-challenge')}</SText>
        </Card>
        <MotionIcon onClick={() => user && setUser({ ...user, motionViewed: false })}>
            <img src={Camera} alt="card-back"/>
        </MotionIcon>
        <ChallengeCardsContainer>
            <SSticker>
                <img src={BackgroundChallenge} alt="button" />
                <SText $fontsize='24px'>{i18n._('common.challenges-inprogress')}</SText>
            </SSticker>
            <ChallengeCards>
                {_trackings.map((_tracking, index) => _tracking === null ? (
                    <ChallengeCardEmpty key={index}/>             
                ) : (
                    <ChallengeCard key={index} $status={_tracking.challengeStatus} onClick={() => setTrackingId(_tracking.id)}>
                        <ThematicIcon src={resolveImg({url: new URL(`@assets/images/`, import.meta.url).href,imageName: _tracking.challenge.thematic.asset?? '',})} alt={_tracking.challenge.thematic.name} />
                        <img src={resolveBg(_tracking.challengeStatus)} alt={_tracking.challenge.thematic.name} width="120%" />
                        <SText $fontsize='16px' $fontFamily='Bangers' $color={resolveColor(_tracking.challengeStatus)}>{_tracking.challenge.thematic.name}</SText>
                        <SText $fontsize='13px' $color={resolveColor(_tracking.challengeStatus)}>{_tracking.challenge.difficulty === ChallengeDifficulty.EASY ? 'Facile': 'Complexe'}</SText>
                        <StatusIcon src={resolveIcon(_tracking.challengeStatus)} alt={_tracking.challengeStatus} />
                    </ChallengeCard>
                ))}
            </ChallengeCards>
        </ChallengeCardsContainer>
        <SModal
            title={<SText $fontsize='26px'>{i18n._('common.impossible-draw')}</SText>}
            open={open}
            footer={null}
            closeIcon={null}
            width={350}
            className="modalStyle"
            centered
        >
            <SText $fontsize='14px'>{i18n._('common.impossible-draw-message')}</SText>
        </SModal>
        {open &&
        <SCloseButton onClick={() => setOpen(false)}>
            <SText $fontsize='18px' $texttransform='lowercase' onClick={() => setOpen(false)}>{i18n._('common.close')}</SText>
        </SCloseButton>
        }
        {trackingId &&
        <SCloseButton onClick={() => setTrackingId(null)}>
            <SText $fontsize='18px' $texttransform='lowercase' onClick={() => setOpen(false)}>{i18n._('common.close')}</SText>
        </SCloseButton>
        }
        {trackingId !== null && <ChallengeModal trackingId={trackingId} setTrackingId={setTrackingId}/>}
        <ChallengePanel open={openPanel} setOpen={setOpenPanel} />
    </Container>
    );
} 

export default Hub;

const Container = styled.div`
    position: relative;
    width: 100%;
    height: 100%;
    background: url(${Background});
    background-repeat: no-repeat;
    background-size: cover;
    display: flex;
    flex-direction: column;
    justify-content: start;
    align-items: center;
    overflow: hidden;
    padding-top: 56px;
`;

const Card = styled.div`
    width: 45%;
    position: relative;
    && > img {
        width: 100%;
        margin-bottom: 10px;
        cursor: pointer;
    }
`;

const MotionIcon = styled.div`
    position: absolute;
    top: -3px;
    right: -3px;
    width: 56px;
    height: 56px;
    transform: rotate(-5deg);
    display: flex;
    justify-content: center;
    align-items: center;
    flex-shrink: 0;
    border-bottom: 2px solid #000;
    border-left: 2px solid #000;
    background: #FFF;
    box-shadow: 4px 4px 0px 0px rgba(0, 0, 0, 0.25);
    cursor: pointer;

    && img {
        width: 40px;
    }
`

const ChallengeCardsContainer = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-top: 30px;
`;

const SSticker = styled.div`
  all: unset;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 200px;
  height: 50px;
    
  &&& img {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
  }

  &&& p {
    position: absolute;
    width: 80%;
    top: 50%;
    left: 50%;
    transform: rotate(-2deg) translate(-50%, -50%);
  }
`;

const ChallengeCards = styled.div`
    width: 100%;
    background: rgba(255, 255, 255, 0.20);
    box-shadow: 4px 5px 0px 0px rgba(0, 0, 0, 0.25);
    display: flex;
    gap: 15px;
    justify-content: center;
    align-items: center;
    padding: 25px;
    margin-top: -15px;
`;

const ChallengeCardEmpty = styled.div`
    border-radius: 4.3px;
    border: 2px solid rgba(0, 0, 0, 0.50);
    background: #C17B72;
    box-shadow: 0px -1.433px 0px 0px #000;
    align-self: stretch;
    width: 25%;
    min-height: 120px;

`

const ChallengeCard = styled.div<{$status: ChallengeStatus}>`
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border-radius: 4.3px;
    border: ${({ $status }) => {
        switch ($status) {
            case ChallengeStatus.BRONZE:
                return '2px solid #B38261';
            case ChallengeStatus.SILVER:
                return '2px solid #838EA1';
            case ChallengeStatus.GOLD:
                return '2px solid #DF781B';
            case ChallengeStatus.PLATINUM:
                return '2px solid #9F6FFF';
        }
    }};
    background: #fff;
    box-shadow: ${({ $status }) => {
        switch ($status) {
            case ChallengeStatus.BRONZE:
                return '0px 2px 0px 0px  #B38261';
            case ChallengeStatus.SILVER:
                return '0px 2px 0px 0px  #838EA1';
            case ChallengeStatus.GOLD:
                return '0px 2px 0px 0px  #DF781B';
            case ChallengeStatus.PLATINUM:
                return '0px 2px 0px 0px  #9F6FFF';
        }
    }};
    align-self: stretch;
    width: 25%;
    padding: 1% 0;
    cursor: pointer;
`

export const StatusIcon = styled.img`
    position: absolute;
    top: -10px;
    right: -10px;
    width: 30px;
`
    
export const ThematicIcon = styled.img`
    position: absolute;
    top: 10%;
    left: 50%;
    transform: translateX(-50%);
    width: 56px;
`

export const SModal = styled(Modal)`
    &&& .ant-modal-content {
        border-radius: 24px;
        border-top: 2px solid #000;
        border-right: 2px solid #000;
        border-bottom: 6px solid #000;
        border-left: 2px solid #000;
        background: #FFF;
        box-shadow: 4px 5px 0px 0px rgba(0, 0, 0, 0.25);
    }
`

export const SCloseButton = styled.div`
    position: absolute;
    border-top: 2px solid #000;
    border-right: 2px solid #000;
    border-left: 2px solid #000;
    background: #FFF;
    box-shadow: 4px -5px 0px 0px rgba(0, 0, 0, 0.25);    
    bottom: -1%;
    transform: translateX(-50%);
    width: 100%;
    z-index: 1001; 
    width: 30%;
    height: 70px;
    transform: rotate(-3.634deg);
    display: flex;
    justify-content: center;
    align-items: start;
    cursor: pointer;
    padding-top: 7px;

    && p {
        transform: rotate(3.634deg);
    }
`   

const HelpBox = styled.div`
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    padding: 10%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    cursor: pointer;

    && img {

    } 
`