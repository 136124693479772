import { env } from '~/helpers/env';

export const resolveImg = ({
  url,
  imageName,
}: {
  url: string;
  imageName: string;
}): string => {
  if (env.app.env === 'local') {
    return url + imageName;
  }

  const tmp = url
    .replace('@', '')
    .replace('assetsVite/js/', '')
    .replace('assets/', 'assets/src/');

  return tmp + imageName;
};
