import { GraphQLTypes, InputType, Selector, $ } from '@zeus/index';
import { typedGql } from '@zeus/typedDocumentNode';
import { trackingSelector } from '~/gql/tracking/tracking';
import { scalars } from '~/gql/utils/scalars';

const usersSelector = Selector('Users')({
  email: true,
  id: true,
  updatedAt: true,
  uuid: true,
  firstName: true,
  lastName: true,
  tutorEmail: true,
  satisfactionQuiz: true,
  motionViewed: true,
  firstCardDraw: true,
  unlockedDate: true,
  satisfactionQuizContent: true,
  role: {
    name: true,
    id: true,
  },
});

const userWithTrackingSelector = Selector('Users')({
  email: true,
  id: true,
  updatedAt: true,
  uuid: true,
  firstName: true,
  lastName: true,
  tutorEmail: true,
  satisfactionQuiz: true,
  motionViewed: true,
  firstCardDraw: true,
  unlockedDate: true,
  role: {
    name: true,
    id: true,
  },
  trackingEntity: trackingSelector,
});

const usersPaginationSelector = Selector('UserPagination')({
  users: usersSelector,
  total: true,
});

export const GQL_ADD_USER = typedGql('mutation')({
  createUser: [
    {
      createUserInput: $('createUserInput', 'CreateUserInput!'),
    },
    usersSelector,
  ],
});

export const GQL_UPDATE_USER = typedGql('mutation')({
  updateUser: [
    { updateUserInput: $('updateUserInput', 'UpdateUserInput!') },
    usersSelector,
  ],
});

export const GQL_DELETE_USER = typedGql('mutation')({
  deleteUser: [
    {
      id: $('id', 'Int!'),
    },
    true,
  ],
});

export const GQL_USER = typedGql('query', { scalars })({
  getUser: [{ id: $('id', 'Int!') }, userWithTrackingSelector],
});

export const GQL_USERS_WITH_FILTERS = typedGql('query', { scalars })({
  getFilteredUsers: [
    {
      findManyUserWithFiltersInput: $('input', 'FindManyUserWithFiltersInput!'),
    },
    usersPaginationSelector,
  ],
});

export const GQL_USERS = typedGql('query', { scalars })({
  getUsers: {
    ...usersSelector,
  },
});

export const GQL_UPDATE_TUTOR_EMAIL = typedGql('mutation')({
  updateTutorEmail: [
    {
      userId: $('userId', 'Int!'),
      tutorEmail: $('tutorEmail', 'String!'),
    },
    usersSelector,
  ],
});

export const GQL_UPDATE_SATISFACTION_QUIZ = typedGql('mutation')({
updateSatisfactionQuiz: [
  {
    userId: $('userId', 'Int!'),
    content: $('content', 'JSON!'),
  },
  usersSelector,
],
});

export const GQL_UPDATE_MOTION_VIEWED = typedGql('mutation')({
updateMotionViewed: [
  {
    userId: $('userId', 'Int!'),
  },
  usersSelector,
],
});

export const GQL_UPDATE_FIRST_CARD_DRAW = typedGql('mutation')({
  updateFirstCardDraw: [
    {
      userId: $('userId', 'Int!'),
    },
    usersSelector,
  ],
  });

export type StoredUsers = InputType<
  GraphQLTypes['Users'],
  typeof usersSelector
>;

export type StoredUserWithTracking = InputType<
  GraphQLTypes['Users'],
  typeof userWithTrackingSelector
>;
