import { GraphQLTypes, InputType, Selector } from "@zeus/index";

export const quizSelector = Selector('Quiz')({
    id: true,
    name: true,
    question: true,
    quizType: true,
    answers: {
        id: true,
        name: true,
        text: true,
        isCorrect: true,
    },
});

export type StoredQuiz = InputType<
  GraphQLTypes['Quiz'],
  typeof quizSelector
>;
  