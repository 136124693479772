import { useEffect } from 'react';
import { useSearchParams } from 'react-router-dom';
import { env } from '~/helpers/env';
import { useTuteur } from '~/helpers/store/tuteur';

function GetTuteurEmail() {
  const [searchParams] = useSearchParams();
  const tuteur = searchParams.get('tuteur');
  const { setTuteur } = useTuteur();

  useEffect(() => {
    setTuteur(tuteur ?? '');
    window.location.replace(`${env.api.url}/api/auth/sso/saml/login`);
  }, [tuteur]);

  return <div></div>;
}

export default GetTuteurEmail;
