import {  useMutation } from '@apollo/client';
import { useLingui } from '@lingui/react';
import { Form } from 'antd';
import { useState } from 'react';
import styled from 'styled-components';
import Background from '@assets/images/background-quiz.png';
import { SText } from '~/utils/ui/utils';
import { authClient } from '~/helpers/apollo';
import { SButton } from '~/pages/authentication/Login';
import uncheckIcon from '@assets/images/icon-unselected.png';
import checkIcon from '@assets/images/icon-selected.png';
import { useUser } from '~/helpers/store/user';
import { GQL_UPDATE_SATISFACTION_QUIZ } from '~/gql/users/users';

export type CustomQuestion = {
  question: string;
  answer: string;
}

function SatisfactionQuiz() {
  const questions = ['As-tu apprécié le module dans son ensemble ?', 'As-tu trouvé que le module était facile à suivre ?', 'La navigation dans le module a-t-elle été fluide ?', 'Le temps alloué pour compléter le module était-il suffisant ?', 'La formation t’a-t-elle paru complète ?', 'Le module visait à découvrir les leviers de l’inclusion en entreprise. D’après toi, est-ce que cet objectif a été atteint ?', 'Les consignes, activités et feedbacks ont-ils été suffisamment clairs ?', 'Après les défis, penses-tu mettre en application les connaissances acquises à travers le module dans ta future entreprise ?', 'Après avoir suivi le module, te sens-tu capable d\'être plus inclusif dans ton métier demain ?', 'Recommanderais-tu ce module à d’autres étudiants ?'];
  const answers = ['Oui', 'Plutôt', 'Je ne sais pas', 'Pas vraiment', 'Non'];
  const [selected, setSelected] = useState<string>('');
  const [currentIndex, setCurrentIndex] = useState<number>(0);
  const [results, setResults] = useState<CustomQuestion[]>([]);
  const { user, setUser } = useUser();
  const { i18n } = useLingui();

  const [updateSatisfactionQuiz] = useMutation(GQL_UPDATE_SATISFACTION_QUIZ, {
    client: authClient,
    onCompleted: (data) => {
      setUser(data.updateSatisfactionQuiz);
    },
    onError: (error) => {
      console.error(error);
    },
  });

  const updateHandler = async () => {
   if(selected.length === 0) return;
   if(!user?.id) return;

   if(currentIndex === questions.length - 1) {
     await updateSatisfactionQuiz({ variables: { userId: user.id, content: JSON.stringify(results)  } });
   }

    setResults([...results, { question: questions[currentIndex], answer: selected }]);
    setSelected('');
    setCurrentIndex(currentIndex + 1);
  }; 


  return (
    <SForm onFinish={updateHandler} initialValues={{ remember: true }}>
      <SText $fontweight='5' $fontsize='14px'>{i18n._('before-quiz.message')}</SText>
      <Question>
        <SText>{questions[currentIndex]}</SText>
      </Question>
        <Answers>
            {answers.map((answer, index) => (
                <Answer key={index} $selected={selected === answer} onClick={() => selected=== answer ? setSelected('') : setSelected(answer)}>
                    <SText>{answer}</SText>
                    <img src={selected === answer ? checkIcon : uncheckIcon} alt="uncheckIcon" />
                </Answer>
            ))}
        </Answers>
      <SButton $disabled={selected.length === 0} $width='60%'>
        <SText $fontsize='20px'>{i18n._('common.validate')}</SText>
      </SButton>
      <SText $fontsize='24px' $color='#fff' $textShadow='2px 2px 0px #000' $margin='30px 0 0' $fontFamily='Bangers'>{currentIndex + 1 + '/' + questions.length}</SText>
    </SForm>
  );
}

export default SatisfactionQuiz;

export const SForm = styled(Form)`
  width: 100%;
  height: 100%;
  background: url(${Background});
  background-repeat: no-repeat;
  background-size: cover;
  padding: 50px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 15px;
  align-items: center;

  & .customInput {
    width: 100%;

    & span:nth-child(1),
    & input {
      width: 100%;
      height: 50px;
      border-radius: 10px;
      padding: 0 15px;
      font-size: 16px;
      border-radius: 4px;
      border: 2px solid #000;
      text-align: center;
    }
  }

`;

const Question = styled.div`
    display: flex;
    border-radius: 10px;
    background: #E580A7;    
    transform: rotate(-2deg);
    padding: 8px 12px;
    justify-content: center;
    align-items: center;
    gap: 8px;
    margin: 16px 0;
    align-self: stretch;
`;

const Answers = styled.div`
    display: flex;
    flex-wrap: wrap;
    gap: 12px;
    width: 100%;
    flex-direction: column;
    justify-content: center;
`;

const Answer = styled.div<{$selected: boolean}>`
    position: relative;
    display: flex;
    padding: 12px 20px;
    justify-content: center;
    align-items: center;
    gap: 8px;
    align-self: stretch;
    border-radius: 4px;
    border: 2px solid #000;
    cursor: pointer;

    background: ${({ $selected }) => $selected ? '#fff' : 'unset'};
    box-shadow: ${({ $selected }) => $selected ? '0px 2px 0px 0px #000' : 'unset'};

    &&& img {
        width: 30px;
        position: absolute;
        right: -10px;
    }
`;

