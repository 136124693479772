import motion from "@assets/videos/motion.mp4"
import styled from "styled-components";
import { useRef, useState } from "react";
import { useMutation } from "@apollo/client";
import { authClient } from "~/helpers/apollo";
import { useUser } from "~/helpers/store/user";
import { GQL_UPDATE_MOTION_VIEWED } from "~/gql/users/users";
import { PlayCircleFilled } from "@ant-design/icons";

function Motion(){
    const { user, setUser } = useUser();
    const [started, setStarted] = useState<boolean>(false)
    const ref = useRef<HTMLVideoElement>(null);

    const [updateMotionViewed] = useMutation(GQL_UPDATE_MOTION_VIEWED, {
        client: authClient,
        onCompleted: (data) => {
          setUser(data.updateMotionViewed);
        },
        onError: (error) => {
          console.error(error);
        },
      });

    const handleNext = async () => {
        if(!user?.id) return;

        await updateMotionViewed({ variables: { userId: user.id  } });
    }

    return (
        <Container onClick={() => {
            ref.current?.play()
            setStarted(true)
        }} >
            <Video playsInline src={motion} ref={ref} 
                onEnded={handleNext}/>
            {!started && <SPlayCircleFilled />}
        </Container>
    )
}

export default Motion;

const Container = styled.div`
    width: 100%;
    height: 100%;
    background-repeat: no-repeat;
    background-size: cover;
    display: flex;
    justify-content: center;
    align-items: center;
`;

const Video = styled.video`
    width: 100%;
    height: 100%;
    object-fit: cover;
`;

const SPlayCircleFilled = styled(PlayCircleFilled)`
    position: absolute;
    font-size: 5rem;
    color: white;
    cursor: pointer;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
`;