import {
  LogoutOutlined,
  TeamOutlined,
} from '@ant-design/icons';
import MiniLogo from '@assets/images/logo-defi.png';
import { useLingui } from '@lingui/react';
import { Divider, Layout, Menu } from 'antd';
import { useEffect, useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import styled from 'styled-components';
import { useAuth } from '~/helpers/store/auth';

const { Sider } = Layout;

type MenuItem = { key: string; icon: JSX.Element; label: JSX.Element };

function NavBar() {
  const { i18n } = useLingui();
  const [selectedKey, setSelectedKey] = useState<string>('');
  const { payload } = useAuth();

  const location = useLocation();
  const { pathname } = location;

  useEffect(() => {
    const path = pathname.split('/')[2] || '';
    setSelectedKey(path);
  }, [pathname]);

  const adminItems: MenuItem[] = [
    {
      key: 'users',
      icon: <TeamOutlined />,
      label: (
        <div>
          {i18n._('common.users')}
          <Link to="/bo/users" />
        </div>
      ),
    },
  ];

  const menuItems: MenuItem[] = [
    // {
    //   key: 'files',
    //   icon: <FileSyncOutlined />,
    //   label: (
    //     <div>
    //       {i18n._('common.files')}
    //       <Link to="/bo/files" />
    //     </div>
    //   ),
    // },
  ];

  const menuLogout: MenuItem[] = [
    {
      key: 'logout',
      icon: <LogoutOutlined />,
      label: (
        <div>
          {i18n._('common.logout')}
          <Link to="/logout" />
        </div>
      ),
    },
  ];

  const allMenuItems =
    payload?.roles === 'Admin'
      ? [...adminItems, ...menuItems, ...menuLogout]
      : [...menuItems, ...menuLogout];

  return (
    <Sider collapsible breakpoint="lg" theme="light">
      <SDivLogo>
        <Link to="/bo/home">
          <img
            src={MiniLogo}
            alt="mini-logo"
            onClick={() => setSelectedKey('')}
          />
        </Link>
      </SDivLogo>
      <Divider />
      <Menu
        theme="light"
        mode="inline"
        style={{ borderInlineEnd: 'none' }}
        selectedKeys={[selectedKey]}
        items={allMenuItems}
      />
    </Sider>
  );
}

export default NavBar;

const SDivLogo = styled.div`
  position: relative;
  height: 8%;
  margin-top: 10px;
  margin-bottom: 10px;
  width: 100%;

  img {
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    object-fit: contain;
    position: absolute;
    width: 80%;
  }
`;
