import { RedoOutlined } from '@ant-design/icons';
import { useLazyQuery, useQuery } from '@apollo/client';
import { useLingui } from '@lingui/react';
import { FloatButton, Space } from 'antd';
import type { ColumnsType } from 'antd/es/table';
import { useEffect, useState } from 'react';
import { GQL_USERS_WITH_FILTERS, StoredUsers } from '~/gql/users/users';
import { authClient } from '~/helpers/apollo';
import UserAddModal from '~/pages/backoffice/admin/user/components/UserAddModal';
import UserDeleteModal from '~/pages/backoffice/admin/user/components/UserDeleteModal';
import UserSatisfactionQuiz from '~/pages/backoffice/admin/user/components/UserSatisfactionQuiz';
import UserUpdateModal from '~/pages/backoffice/admin/user/components/UserUpdateModal';
import { returnOrderBy } from '~/pages/backoffice/sorter';
import { downloadCSV } from '~/utils/exportCsv/utils';
import CommonTable from '~/utils/table/CommonTable';

function UserTable() {
  const [users, setUsers] = useState<StoredUsers[]>([]);
  const [usersExported, setUsersExported] = useState<StoredUsers[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [total, setTotal] = useState<number>(0);
  const [page, setPage] = useState<number>(1);
  const [sortedInfo, setSortedInfo] = useState<any>();
  const [filterInfo, setFilterInfo] = useState<any>();
  const [pageSize, setPageSize] = useState<number>(10);
  const [openAdd, setOpenAdd] = useState<boolean>(false);
  const [openEdit, setOpenEdit] = useState<boolean>(false);
  const [openDelete, setOpenDelete] = useState<boolean>(false);
  const [, setOpenImport] = useState<boolean>(true);
  const [openSatisfactionQuiz, setOpenSatisfactionQuiz] = useState<boolean>(false);
  const [searchText, setSearchText] = useState('');
  const [rowSelected, setRowSelect] = useState<StoredUsers>();
  const [skipQuery, setSkipQuery] = useState(true);
  const [yScroll] = useState(true);
  const [xScroll] = useState<string>();
  const { i18n } = useLingui();

  const columns: ColumnsType<StoredUsers> = [
    {
      title: `${i18n._('common.email')}`,
      dataIndex: 'email',
      key: 'email',
      width: 150,
      sorter: (a, b) => a.email.localeCompare(b.email),
      sortOrder: sortedInfo?.columnKey === 'email' && sortedInfo.order,
      render: (_, { email }) => <p>{email}</p>,
    },
    {
      title: `${i18n._('common.firstname')}`,
      dataIndex: 'firstName',
      key: 'firstName',
      width: 150,
      sorter: (a, b) => a.firstName.localeCompare(b.firstName),
      sortOrder: sortedInfo?.columnKey === 'firstName' && sortedInfo.order,
      render: (_, { firstName }) => <p>{firstName}</p>,
    },
    {
      title: `${i18n._('common.lastname')}`,
      dataIndex: 'lastName',
      key: 'lastName',
      width: 150,
      sorter: (a, b) => a.lastName.localeCompare(b.lastName),
      sortOrder: sortedInfo?.columnKey === 'lastName' && sortedInfo.order,
      render: (_, { lastName }) => <p>{lastName}</p>,
    },
    {
      title: `${i18n._('common.role')}`,
      key: 'role',
      dataIndex: 'role',
      width: 150,
      filters: [
        {
          text: 'Admin',
          value: 1,
        },
        {
          text: 'User',
          value: 2,
        },
        {
          text: 'Formateur',
          value: 3,
        },
        {
          text: 'Guest',
          value: 4,
        },
      ],
      sorter: (a, b) => a.role.name.localeCompare(b.role.name),
      sortOrder: sortedInfo?.columnKey === 'role' && sortedInfo.order,
      render: (_, { role }) => <p>{role.name}</p>,
    },
    {
      title: `${i18n._('common.lastConnection')}`,
      key: 'lastConnectionAt',
      dataIndex: 'lastConnectionAt',
      sorter: (a, b) =>
        (new Date(a.updatedAt as Date) as any) -
        (new Date(b.updatedAt as Date) as any),
      sortOrder:
        sortedInfo?.columnKey === 'lastConnectionAt' && sortedInfo.order,
      width: 150,

      render: (_, { updatedAt }) => (
        <p>
          {updatedAt !== null
            ? new Date(updatedAt as string).toLocaleString('fr')
            : '/'}
        </p>
      ),
    },
    {
      title: `${i18n._('common.satisfactionQuiz')}`,
      key: 'satisfactionQuiz',
      dataIndex: 'satisfactionQuiz',
      width: 150,

      render: (_,  record ) => record.satisfactionQuiz ?  (
        <a
        onClick={() => {
          setRowSelect(record);
          setOpenSatisfactionQuiz(true);
        }}
      >
        {i18n._('common.consult')}
      </a>
      ) : (
        <p>{i18n._('common.notDone')}</p>
      ),
    },
    {
      title: `${i18n._('common.action')}`,
      key: 'action',
      width: 150,
      render: (_: any, record: StoredUsers) => (
        <Space size="small">
          <a
            onClick={() => {
              setRowSelect(record);
              setOpenEdit(true);
            }}
          >
            {i18n._('common.update')}
          </a>
          <a
            onClick={() => {
              setRowSelect(record);
              setOpenDelete(true);
            }}
          >
            {i18n._('common.delete')}
          </a>
        </Space>
      ),
    },
  ];

  const tableColumns = columns.map((item) => ({ ...item }));
  if (xScroll === 'fixed') {
    tableColumns[0].fixed = true;
    tableColumns[tableColumns.length - 1].fixed = 'right';
  }

  const { refetch: fetchUsers } = useQuery(GQL_USERS_WITH_FILTERS, {
    client: authClient,
    skip: skipQuery,
    notifyOnNetworkStatusChange: true,
    fetchPolicy: 'network-only',
    variables: {
      input: {
        skip: page <= 1 ? 0 : (page - 1) * 10,
        take: pageSize,
        searchInput: searchText,
        orderBy:
          sortedInfo && returnOrderBy(sortedInfo.field, sortedInfo.order),
        filterBy: filterInfo && filterInfo,
      },
    },
    onCompleted: (data) => {
      setUsers(data.getFilteredUsers.users);
      setTotal(data.getFilteredUsers.total);
      setLoading(false);
    },
  });

  const [fetchExportedUsers] = useLazyQuery(GQL_USERS_WITH_FILTERS, {
    client: authClient,
    onCompleted: (data) => {
      setUsersExported(data.getFilteredUsers.users);
    },
  });

  const handleRefreshUser = async () => {
    setLoading(true);
    await fetchUsers();
    setLoading(false);
  };

  const handleExportUsers = async () => {
    await fetchExportedUsers({
      variables: {
        input: {
          skip: 0,
          take: 9999,
          searchInput: searchText,
        },
      },
    });
  };

  function exportCsv() {
    downloadCSV(
      i18n._('common.users'),
      [
        i18n._('common.email'),
        i18n._('common.firstname'),
        i18n._('common.lastname'),
        i18n._('common.role'),
        i18n._('common.lastConnection'),
      ],
      usersExported.map((user) => {
        const date = new Date(user.updatedAt as Date);
        const dateFr = date.toLocaleDateString('fr-FR', {
          year: 'numeric',
          month: 'long',
          day: 'numeric',
        });
        return [
          user.email,
          user.firstName,
          user.lastName,
          user.role.name,
          dateFr,
        ];
      })
    );
  }

  useEffect(() => {
    setSkipQuery(false);
    void handleRefreshUser();
    return () => setSkipQuery(true);
  }, [page, pageSize, sortedInfo, searchText]);

  useEffect(() => {
    void handleExportUsers();
  }, []);

  return (
    <div style={{ paddingTop: ' 25px' }}>
      <CommonTable
        data={users}
        loading={loading}
        total={total}
        refresh={handleRefreshUser}
        columns={columns}
        xScroll={xScroll}
        yScroll={yScroll}
        showFooter={false}
        showHeader={true}
        headerTitle={i18n._('user.management')}
        selectionType="checkbox"
        top="none"
        bottom="bottomRight"
        addButton
        importButton
        exportButton
        setOpenAdd={setOpenAdd}
        setOpenEdit={setOpenEdit}
        setOpenImport={setOpenImport}
        exportCsv={exportCsv}
        page={page}
        pageSize={pageSize}
        setPage={setPage}
        setPageSize={setPageSize}
        setSortedInfo={setSortedInfo}
        setFilterInfo={setFilterInfo}
        setSearchText={setSearchText}
        search={true}
      />
      {openAdd && (
        <UserAddModal
          open={openAdd}
          setOpen={setOpenAdd}
          refresh={handleRefreshUser}
        />
      )}

      {openEdit && rowSelected && (
        <UserUpdateModal
          open={openEdit}
          setOpen={setOpenEdit}
          refresh={handleRefreshUser}
          rowSelected={rowSelected}
        />
      )}

      {openDelete && rowSelected && (
        <UserDeleteModal
          open={openDelete}
          setOpen={setOpenDelete}
          refresh={handleRefreshUser}
          rowSelected={rowSelected}
        />
      )}
      {openSatisfactionQuiz && rowSelected && (
        <UserSatisfactionQuiz open={openSatisfactionQuiz} setOpen={setOpenSatisfactionQuiz} rowSelected={rowSelected}/>
      )}
      <FloatButton
        onClick={handleRefreshUser}
        icon={<RedoOutlined style={{ fontSize: '20px' }} />}
        type="primary"
        style={{ right: 24, width: '50px', height: '50px', bottom: 24 }}
      />
    </div>
  );
}

export default UserTable;
