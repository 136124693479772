import { CloseOutlined } from '@ant-design/icons';
import { useLingui } from '@lingui/react';
import { Modal } from 'antd';
import { useEffect, useState } from 'react';
import styled from 'styled-components';
import { StoredUsers } from '~/gql/users/users';
import { CustomQuestion } from '~/pages/authentified/components/SatisfactionQuiz';
import { classic } from '~/themes';
import { SH2, SText, StyledButton } from '~/utils/ui/utils';

type IProps = {
  open: boolean;
  setOpen: (open: boolean) => void;
  rowSelected: StoredUsers;
};

function UserSatisfactionQuiz({ open, setOpen, rowSelected }: IProps) {
  const { i18n } = useLingui();
  const [questions, setQuestions] = useState<CustomQuestion[]>([]);

  useEffect(() => {
    if (rowSelected.satisfactionQuizContent) {
        setQuestions(JSON.parse(rowSelected.satisfactionQuizContent as string));
    }
  }, [rowSelected])


  return (
    <Modal
      open={open}
      centered
      closeIcon={false}
      style={{ textAlign: 'center' }}
      footer={false}
      onCancel={() => setOpen(false)}
    >
      <SH2 $color={classic.token?.colorPrimary}>{i18n._('common.satisfactionQuiz')}</SH2>
      {questions.map((question, index) => (
        <QuestionBox key={index}>
            <SText $fontweight="bold" $textalign='left' $color='#fff'>
                Question : {question.question}
            </SText>
            <SText $textalign='left' $color='#fff'>
                Réponse : {question.answer}
            </SText>
        </QuestionBox>
        ))}
      <StyledButton $margin="15px 0 0" onClick={() => setOpen(false)}>
        {i18n._('common.close')}
      </StyledButton>
      <CloseOutlined
        style={{
          position: 'absolute',
          top: '10px',
          right: '10px',
          fontSize: '25px',
          cursor: 'pointer',
          color: classic.token?.colorFillSecondary,
        }}
        onClick={() => setOpen(false)}
      />
    </Modal>
  );
}

export default UserSatisfactionQuiz;


const QuestionBox = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: start;
    align-items: start;
    background-color: ${classic.token?.colorPrimary};
    border-radius: 10px;
    padding: 10px;
    margin: 10px;
    box-shadow: 0 0 10px ${classic.token?.colorFillSecondary};
    `;