import { create } from 'zustand';
import { persist } from 'zustand/middleware';
import { StoredTracking } from '~/gql/tracking/tracking';

type TrackingState = {
  trackings: StoredTracking[] | [];
  setTrackings: (trackings: StoredTracking[]) => void;
};

export const useTracking = create<TrackingState>()(
  persist(
    (set) => ({
        trackings: [],
        setTrackings: (trackings: StoredTracking[]) => {
        set({
            trackings,
        });
      },
    
    }),
    {
      name: 'tracking-storage',
    }
  )
);
