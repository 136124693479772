import { create } from 'zustand';
import { persist } from 'zustand/middleware';
import { StoredQuiz } from '~/gql/quiz/quiz';

type QuizState = {
  quiz: { quiz: StoredQuiz, trackingId: number } | null;
  setQuiz: (quiz: { quiz: StoredQuiz, trackingId: number } | null) => void;
};

export const useQuiz = create<QuizState>()(
  persist(
    (set) => ({
        quiz: null,
        setQuiz: (quiz: { quiz: StoredQuiz, trackingId: number } | null) => {
        set({
            quiz,
        });
      },
    
    }),
    {
      name: 'quiz-storage',
    }
  )
);
