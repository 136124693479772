import { GraphQLTypes, InputType, Selector, $ } from "@zeus/index";
import { typedGql } from "@zeus/typedDocumentNode";
import { challengeSelector } from "~/gql/challenge/challenge";

export const trackingSelector = Selector('Tracking')({
    id: true,
    createdAt: true,
    updatedAt: true,
    userId: true,
    challengeStatus: true,
    challengeTrackingStatus: true,
    quizResponses: {
      id: true,
      done: true,
      quizId: true,
      isCorrect: true,
    },
    challenge: challengeSelector,
});

export const GQL_CREATE_TRACKING = typedGql('mutation')({
  createTracking: [
    {
      userId: $('userId', 'Int!'),
      thematicId: $('thematicId', 'Int!'),
    },
    trackingSelector,
  ],
});

export const GQL_DELETE_TRACKING = typedGql('mutation')({
  deleteTracking: [
    {
      trackingId: $('trackingId', 'Int!'),
    },
    true,
  ],
});

export const GQL_VALIDATE_TRACKING = typedGql('mutation')({
  validateTracking: [
    {
      trackingId: $('trackingId', 'Int!'),
      text: $('text', 'String!'),
    },
    true,
  ],
});

export const GQL_VALIDATE_QUIZ = typedGql('mutation')({
  validateQuiz: [
    {
      quizId: $('quizId', 'Int!'),
      trackingId: $('trackingId', 'Int!'),
      answerId: $('answerId', 'Int!'),
    },
    trackingSelector,
  ],
});

export type StoredTracking = InputType<
  GraphQLTypes['Tracking'],
  typeof trackingSelector
>;
  