import styled from 'styled-components';
import Arrow from '@assets/images/arrow.png';
import { useLingui } from '@lingui/react';
import { SText } from '~/utils/ui/utils';
import { ChallengeDifficulty, ChallengeStatus, ChallengeTrackingStatus } from '@zeus/index';
import { useState } from 'react';
import { useUser } from '~/helpers/store/user';
import { resolveImg } from '~/utils/imgResolver';
import { resolveBg, resolveColor, resolveIcon, SCloseButton, StatusIcon, ThematicIcon } from '~/pages/authentified/components/Hub';
import ChallengeModal from '~/pages/authentified/components/ChallengeModal';

interface IProps {
    open: boolean;
    setOpen: (open: boolean) => void;
}

function ChallengePanel ({ open, setOpen }: IProps) {
    const { i18n } = useLingui();
    const { user } = useUser();
    const  [trackingId, setTrackingId] = useState<(number | null)>(null)

    return (
        <Container $open={open}>
            <Layout>
                <SText $fontsize='24px'>{i18n._("challenge.validate")}</SText>
                <GridBox>
                    {user?.trackingEntity?.filter(_tracking => _tracking.challengeTrackingStatus === ChallengeTrackingStatus.COMPLETED).map(_tracking => 
                        <ChallengeCard key={_tracking.id} $status={_tracking.challengeStatus} onClick={() => setTrackingId(_tracking.id)}>
                            <ThematicIcon src={resolveImg({url: new URL(`@assets/images/`, import.meta.url).href,imageName: _tracking.challenge.thematic.asset?? '',})} alt={_tracking.challenge.thematic.name} />
                            <img src={resolveBg(_tracking.challengeStatus)} alt={_tracking.challenge.thematic.name} width="120%" />
                            <SText $fontsize='16px' $fontFamily='Bangers' $color={resolveColor(_tracking.challengeStatus)}>{_tracking.challenge.thematic.name}</SText>
                            <SText $fontsize='13px' $color={resolveColor(_tracking.challengeStatus)}>{_tracking.challenge.difficulty === ChallengeDifficulty.EASY ? 'Facile': 'Complexe'}</SText>
                            <StatusIcon src={resolveIcon(_tracking.challengeStatus)} alt={_tracking.challengeStatus} />
                        </ChallengeCard>
                    )}
                </GridBox>
                {trackingId &&
                <SCloseButton onClick={() => setTrackingId(null)}>
                    <SText $fontsize='18px' $texttransform='lowercase'>{i18n._('common.close')}</SText>
                </SCloseButton>
                }
            </Layout>
            {trackingId !== null && <ChallengeModal trackingId={trackingId} setTrackingId={setTrackingId}/>}
            <Button $open={open} onClick={() => setOpen(!open)}>
                <img src={Arrow} alt="arrow" width={26}/>
            </Button>
        </Container>
    );
} 

export default ChallengePanel;

const Container = styled.div<{$open: boolean}>`
    position: absolute;
    bottom: ${({ $open }) => $open ? '0' : '-76%'};
    left: 0;
    width: 100%;
    height: 76%;
    border-top: 2px solid #000;
    background: #fff;
    transition: bottom 0.5s;
`;

const Layout = styled.div`
    position: relative;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: start;
    align-items: center;
    overflow: auto;
    padding: 10% 5%;
    
`

const Button = styled.div<{$open: boolean}>`
    position: absolute;
    top: -7%;
    left: 50%;
    transform: rotate(-3.634deg) translateX(-50%);
    width: 30%;
    height: 70px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-top: 2px solid #000;
    border-right: 2px solid #000;
    border-left: 2px solid #000;
    background: #FFF;
    box-shadow: 4px -5px 0px 0px rgba(0, 0, 0, 0.25);
    cursor: pointer;

    &&& img {
        transform: ${({ $open }) => $open ? 'rotate(0deg)' : 'rotate(180deg)'};
    }
`

const GridBox = styled.div`
    display: grid;
    justify-content: center;
    align-items: center;
    grid-template-columns: repeat(3, 1fr);
    gap: 30px;
    margin-top: 20px;
`

const ChallengeCard = styled.div<{$status: ChallengeStatus}>`
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border-radius: 8px;
    border: ${({ $status }) => {
        switch ($status) {
            case ChallengeStatus.BRONZE:
                return '4px solid #B38261';
            case ChallengeStatus.SILVER:
                return '4px solid #838EA1';
            case ChallengeStatus.GOLD:
                return '4px solid #DF781B';
            case ChallengeStatus.PLATINUM:
                return '4px solid #9F6FFF';
        }
    }};
    background: #fff;
    box-shadow: ${({ $status }) => {
        switch ($status) {
            case ChallengeStatus.BRONZE:
                return '0px 2px 0px 0px  #B38261';
            case ChallengeStatus.SILVER:
                return '0px 2px 0px 0px  #838EA1';
            case ChallengeStatus.GOLD:
                return '0px 2px 0px 0px  #DF781B';
            case ChallengeStatus.PLATINUM:
                return '0px 2px 0px 0px  #9F6FFF';
        }
    }};
    padding: 2% 0;
    cursor: pointer;
`

