import { GraphQLTypes, InputType, Selector } from "@zeus/index";
import { quizSelector } from "~/gql/quiz/quiz";

export const challengeSelector = Selector('Challenge')({
        id: true,
        name: true,
        description: true,
        asset: true,
        helpText: true,
        thematicId: true,
        thematic :{
            id: true,
            name: true,
            asset: true,
        },
        difficulty: true,
        quizzes: quizSelector,
});

export type StoredChallenge = InputType<
  GraphQLTypes['Challenge'],
  typeof challengeSelector
>;
  