import { useLingui } from '@lingui/react';
import { Form } from 'antd';
import { useState } from 'react';
import styled from 'styled-components';
import Background from '@assets/images/background-quiz.png';
import { SText } from '~/utils/ui/utils';
import { SButton } from '~/pages/authentication/Login';
import uncheckIcon from '@assets/images/icon-unselected.png';
import checkIcon from '@assets/images/icon-selected.png';
import checkIconCorrect from '@assets/images/icon-good.png';
import checkIconWrong from '@assets/images/icon-wrong.png';
import { useQuiz } from '~/helpers/store/quiz';
import { GQL_VALIDATE_QUIZ, StoredTracking } from '~/gql/tracking/tracking';
import { authClient } from '~/helpers/apollo';
import { useMutation } from '@apollo/client';
import { useTracking } from '~/helpers/store/tracking';

function Quiz() {
  const [selected, setSelected] = useState<string>('');
  const [answered, setAnswered] = useState<boolean>(false);
  const [trackingTemp, setTrackingTemp] = useState<StoredTracking | null>(null);
  const { quiz } = useQuiz();
  const { trackings, setTrackings } = useTracking();
  const { i18n } = useLingui();

  const [validateQuiz] = useMutation(GQL_VALIDATE_QUIZ, {
    client: authClient,
    onCompleted: (data) => {
      setAnswered(true);
      setTrackingTemp(data.validateQuiz);
    },  
    onError: (error) => {
      console.error(error);
    },
  });

  const nextQuestion = () => {
    if(trackingTemp) {
      const newTrackings = trackings.map((_tracking) => {
        if(_tracking.id === trackingTemp.id) {
          return trackingTemp;
        }
        return _tracking;
      });
      setTrackings(newTrackings);
      setSelected('');
      setAnswered(false);
      setTrackingTemp(null);
    }
  };


  const validateHandler = async () => {
   if(selected.length === 0) return;
   if(!quiz?.quiz.id) return;
   await validateQuiz({ variables: { quizId: quiz?.quiz.id, trackingId: quiz.trackingId, answerId: quiz.quiz.answers.find((_answer) => _answer.text === selected)?.id as number  } });
  };

  return (
    <SForm onFinish={validateHandler} initialValues={{ remember: true }}>
      <Question>
        <SText>{quiz?.quiz.question}</SText>
      </Question>
      <Answers>
          {quiz?.quiz.answers.map((answer, index) => !answered ? (
              <Answer key={index} $selected={selected === answer.text} onClick={() => selected === answer.text && !answered ? setSelected('') : setSelected(answer.text)}>
                  <SText>{answer.text}</SText>
                  <img src={selected === answer.text ? checkIcon : uncheckIcon} alt="uncheckIcon" />
              </Answer>
          ) : (
              <AnswerAfterValidate key={index} $selected={selected === answer.text} $isCorrect={answer.isCorrect}>
                <SText>{answer.text}</SText>
                {selected === answer.text && 
                  <img src={answer.isCorrect ? checkIconCorrect : checkIconWrong} alt="uncheckIcon" />
                }
                {selected !== answer.text && answer.isCorrect && <img src={checkIconCorrect} alt="uncheckIcon" />}
              </AnswerAfterValidate>
          ))}
      </Answers>
      {answered ? 
        (
        <SButton onClick={nextQuestion} $width='60%'>
          <SText $fontsize='20px'>{i18n._('common.next')}</SText>
        </SButton>
        ) : (
        <SButton $disabled={selected.length === 0} $width='60%'>
          <SText $fontsize='20px'>{i18n._('common.validate')}</SText>
        </SButton>
      )}
    </SForm>
  );
}

export default Quiz;

export const SForm = styled(Form)`
  width: 100%;
  height: 100%;
  background: url(${Background});
  background-repeat: no-repeat;
  background-size: cover;
  padding: 50px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 15px;
  align-items: center;

  & .customInput {
    width: 100%;

    & span:nth-child(1),
    & input {
      width: 100%;
      height: 50px;
      border-radius: 10px;
      padding: 0 15px;
      font-size: 16px;
      border-radius: 4px;
      border: 2px solid #000;
      text-align: center;
    }
  }

`;

const Question = styled.div`
    display: flex;
    border-radius: 10px;
    background: #E580A7;    
    transform: rotate(-2deg);
    padding: 8px 12px;
    justify-content: center;
    align-items: center;
    gap: 8px;
    margin: 16px 0;
    align-self: stretch;
`;

const Answers = styled.div`
    display: flex;
    flex-wrap: wrap;
    gap: 12px;
    width: 100%;
    flex-direction: column;
    justify-content: center;
`;

const Answer = styled.div<{ $selected: boolean }>`
    position: relative;
    display: flex;
    padding: 12px 20px;
    justify-content: center;
    align-items: center;
    gap: 8px;
    align-self: stretch;
    border-radius: 4px;
    border: 2px solid #000;
    cursor: pointer;

    background: ${({ $selected }) => $selected ? '#fff' : 'unset'};
    box-shadow: ${({ $selected }) => $selected ? '0px 2px 0px 0px #000' : 'unset'};

    &&& img {
        width: 30px;
        position: absolute;
        right: -10px;
    }
`;

const AnswerAfterValidate = styled.div<{ $selected: boolean, $isCorrect: boolean }>`
    position: relative;
    display: flex;
    padding: 12px 20px;
    justify-content: center;
    align-items: center;
    gap: 8px;
    align-self: stretch;
    border-radius: 4px;
    border: 2px solid #000;
    opacity: ${({ $selected, $isCorrect }) => !$selected && !$isCorrect ? 0.5 : 1};
    background: ${({ $selected, $isCorrect }) => $selected && $isCorrect ? '#28BF91' : $selected && !$isCorrect ? '#D84E64' : !$selected && $isCorrect ? '#fff' : 'unset'};
    box-shadow: ${({ $selected, $isCorrect }) => $selected || (!$selected && $isCorrect) ? '0px 2px 0px 0px #000' : 'unset'};

    &&& img {
        width: 30px;
        position: absolute;
        right: -10px;
    }
`;

