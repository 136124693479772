import { ReactNode } from 'react';
import styled from 'styled-components';

type IProps = {
  children: ReactNode;
};

function PortraitLayout({ children }: IProps) {
  return (
    <AppContainer>
      <PortraitBox>
        {children}
      </PortraitBox>
    </AppContainer>
  );
}

export default PortraitLayout;



export const AppContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  max-height: 100vh;

  box-sizing: border-box;
`;

export const PortraitBox = styled.div`
  height: 100vh;
  max-height: 100vh;
  aspect-ratio: 9/16;
  background-color: white;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  overflow: hidden;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  box-sizing: border-box;

`;