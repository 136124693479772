import { LoadingOutlined } from '@ant-design/icons';
import { useQuery } from '@apollo/client';
import { useLingui } from '@lingui/react';
import { useEffect, useState } from 'react';
import { keyframes, styled } from 'styled-components';
import { GQL_PING } from '~/gql/common/common';
import { env } from '~/helpers/env';
import Login from '~/pages/authentication/Login';

function App() {
  const [apiCheck, setApiCheck] = useState<boolean>(false);
  const [loadingApi, setLoadingApi] = useState(true);
  const { data, loading } = useQuery(GQL_PING);
  const { i18n } = useLingui();

  const fetchApi = async () => {
    try {
      const response = await fetch(`${env.api.url}/`);
      const resp = await response.json();
      setLoadingApi(false);
      if (resp.status === 'ok') {
        return true;
      }
    } catch (e) {
      setLoadingApi(false);
      return false;
    }
  };

  useEffect(() => {
    const apiVerify = async (): Promise<void> => {
      const result = await fetchApi();
      result ? setApiCheck(true) : setApiCheck(false);
    };
    // eslint-disable-next-line @typescript-eslint/no-floating-promises
    apiVerify();
  }, []);

  return (
    <Container>
      {env.app.env !== 'live' && (
        <TopBarEnv>
          {i18n._('common.environment')} : {env.app.env} | Api
          {loadingApi ? (
            <LoadingOutlined
              style={{ paddingLeft: '10px', paddingRight: '10px' }}
            />
          ) : apiCheck ? (
            ` ${i18n._('is.reachable')} ✅`
          ) : (
            ` ${i18n._('is.not.reachable')} ❌`
          )}{' '}
          | Gql
          {loading ? (
            <LoadingOutlined
              style={{ paddingLeft: '10px', paddingRight: '10px' }}
            />
          ) : data?.getHealthGql.time ? (
            ` ${i18n._('is.reachable')} ✅`
          ) : (
            ` ${i18n._('is.not.reachable')} ❌`
          )}
        </TopBarEnv>
      )}
      <Login />
    </Container>
  );
}

export default App;

const Container = styled.div`
  position: relative;
  width: 100%;
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

const TopBarEnv = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  margin: 0;
  max-width: 100%;
  width: 100%;
  overflow: hidden;
  padding: 5px;
  background-color: #ffff;
  color: #000;
  text-align: center;
  @media screen and (max-width: 800px) {
    display: none;
  }
`;

const gradient = keyframes`
    0% {
        background-position: 0% 0%;
    }
    50% {
        background-position: 100% 100%;
    }
    100% {
        background-position: 0% 0%;
    }
`;

const wave = keyframes`
   2% {
        transform: translateX(1);
    }

    25% {
        transform: translateX(-25%);
    }

    50% {
        transform: translateX(-50%);
    }

    75% {
        transform: translateX(-25%);
    }

    100% {
        transform: translateX(1);
    }
`;

export const Background = styled.div`
  position: absolute;
  margin: auto;
  height: 100%;
  width: 100%;
  overflow: auto;
  background: linear-gradient(
    315deg,
    rgba(101, 0, 94, 1) 3%,
    rgba(60, 132, 206, 1) 38%,
    rgba(48, 238, 226, 1) 68%,
    rgba(255, 25, 25, 1) 98%
  );
  animation: ${gradient} 45s ease infinite;
  background-size: 400% 400%;
  background-attachment: fixed;
  z-index: -1;
  & div {
    background: rgb(255 255 255 / 25%);
    border-radius: 1000% 1000% 0 0;
    position: fixed;
    width: 200%;
    height: 12em;
    animation: wave 10s -3s linear infinite;
    transform: translate3d(0, 0, 0);
    opacity: 0.8;
    bottom: 0;
    left: 0;
    z-index: -1;
  }
  & div:nth-of-type(2) {
    bottom: -1.25em;
    animation: ${wave} 18s linear reverse infinite;
    opacity: 0.8;
  }

  & div:nth-of-type(3) {
    bottom: -2.5em;
    animation: ${wave} 20s -1s reverse infinite;
    opacity: 0.9;
  }
`;
