import { LockOutlined, MailOutlined } from '@ant-design/icons';
import { useMutation } from '@apollo/client';
import { useLingui } from '@lingui/react';
import { Button, Form, Input, notification } from 'antd';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import { GQL_RESET_PASSWORD } from '~/gql/auth/auth';
import {  SForm } from '~/pages/authentication/Login';
import { classic } from '~/themes';
import { SH2, SLink, SText } from '~/utils/ui/utils';

function NewPassword() {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [, setPasswordConfirm] = useState('');
  const navigate = useNavigate();
  const { i18n } = useLingui();
  const [form] = Form.useForm();
  const queryString = window.location.search;
  const urlParams = new URLSearchParams(queryString);
  const token = urlParams.get('token');

  const [resetPassword] = useMutation(GQL_RESET_PASSWORD, {
    onCompleted: (data) => {
      if (data.resetPassword) {
        notification.success({
          message: i18n._('common.reset'),
          description: i18n._('good.reset.message'),
          placement: 'topRight',
        });
        navigate('/');
      }
    },
    onError: () => {
      notification.error({
        message: i18n._('common.reset'),
        description: i18n._('bad.reset.message'),
        placement: 'topRight',
      });
    },
  });

  const resetHandler = async () => {
    if (email && password && token) {
      await resetPassword({
        variables: { email: email, password: password, token: token },
      });
    }
  };

  const validatePassword = async (_rule: any, value: string) => {
    return new Promise((resolve, reject) => {
      const _password = form.getFieldValue('password');
      if (_password && value !== _password) {
        reject(i18n._('password.not-match.message'));
      } else {
        resolve('');
      }
    });
  };

  return (
    <Container>

        <SForm initialValues={{ remember: true }} onFinish={resetHandler}>
          <Form.Item>
            <SH2 $color={classic.token?.colorPrimary}>
              {i18n._('new.password')}
            </SH2>
            <SText $margin="15px 0">{i18n._('new.password.message')}</SText>
          </Form.Item>
          <Form.Item
            name="email"
            className="customInput"
            rules={[
              {
                required: true,
                message: i18n._('input.required.message'),
              },
              {
                pattern: new RegExp(/^[\w-\\.]+@([\w-]+\.)+[\w-]{2,4}$/g),
                message: i18n._('input.email.message'),
              },
            ]}
          >
            <Input
              prefix={<MailOutlined className="site-form-item-icon" />}
              placeholder={i18n._('common.email')}
              value={'raphaelmortier@oulook.fr'}
              onChange={(e) => setEmail(e.target.value)}
              size="large"
              type="text"
            />
          </Form.Item>
          <Form.Item
            name="password"
            className="customInput"
            rules={[{ required: true, message: i18n._('input.password') }]}
          >
            <Input.Password
              prefix={<LockOutlined className="site-form-item-icon" />}
              type="password"
              placeholder={i18n._('new.password')}
              onChange={(e) => setPassword(e.target.value)}
              size="large"
            />
          </Form.Item>

          <Form.Item
            name="passwordConfirm"
            className="customInput"
            rules={[
              { required: true, message: i18n._('input.password-confirm') },
              {
                validator: validatePassword,
              },
            ]}
          >
            <Input.Password
              prefix={<LockOutlined className="site-form-item-icon" />}
              type="password"
              placeholder={i18n._('new.password-confirm')}
              onChange={(e) => setPasswordConfirm(e.target.value)}
              size="large"
            />
          </Form.Item>

          <Form.Item>
            <Button
              type="primary"
              htmlType="submit"
              className="login-form-button"
            >
              {i18n._('reset.password')}
            </Button>
          </Form.Item>

          <SLink
            $color="#9291a5"
            $textdecoration="underline"
            onClick={() => navigate('/')}
          >
            {i18n._('login.return-message')}
          </SLink>
        </SForm>
    </Container>
  );
}

export default NewPassword;

const Container = styled.div`
  position: relative;
  width: 100%;
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;
