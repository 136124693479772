import { create } from 'zustand';
import { persist } from 'zustand/middleware';
import { StoredUserWithTracking } from '~/gql/users/users';

type UserState = {
  user: StoredUserWithTracking | null;
  setUser: (user: StoredUserWithTracking | null) => void;
};

export const useUser = create<UserState>()(
  persist(
    (set) => ({
        user: null,
        setUser: (user: StoredUserWithTracking | null) => {
        set({
            user,
        });
      },
    
    }),
    {
      name: 'user-storage',
    }
  )
);
