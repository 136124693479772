import { useLingui } from "@lingui/react";
import { ChallengeStatus, ChallengeTrackingStatus } from "@zeus/index";
import { Modal } from "antd";
import { useEffect, useState } from "react";
import styled from "styled-components";
import { GQL_DELETE_TRACKING, StoredTracking } from "~/gql/tracking/tracking";
import { useTracking } from "~/helpers/store/tracking";
import { SText } from "~/utils/ui/utils";
import BronzeIcon from '@assets/images/icon-bronze.png'
import SilverIcon from '@assets/images/icon-argent.png'
import GoldIcon from '@assets/images/icon-or.png'
import PlatineIcon from '@assets/images/icon-platine.png'
import BronzeBg from '@assets/images/fond-score-bronze.png'
import SilverBg from '@assets/images/fond-score-argent.png'
import GoldBg from '@assets/images/fond-score-or.png'
import PlatineBg from '@assets/images/fond-score-platine.png'
import Arrow from '@assets/images/fleche_rayee_2.png'
import { resolveImg } from "~/utils/imgResolver";
import { SButton } from "~/pages/authentication/Login";
import { authClient } from "~/helpers/apollo";
import { useMutation } from "@apollo/client";
import { useUser } from "~/helpers/store/user";
import ValidChallenge from "~/pages/authentified/components/ValidChallenge";

interface IProps {
    trackingId: number | null;
    setTrackingId: (trackingId: number | null) => void;
}


function ChallengeModal ({ trackingId, setTrackingId }: IProps) {
  const { trackings, setTrackings } = useTracking();
  const { user } = useUser();
  const [tracking, setTracking] = useState<StoredTracking | null>(null);
  const { i18n } = useLingui();
  const [flipped, setFlipped] = useState<boolean>(false);
  const [validate, setValidate] = useState<boolean>(false);

  const [deleteTracking] = useMutation(GQL_DELETE_TRACKING, {
        client: authClient,
        onCompleted: () => {
            setTrackings(trackings.filter((_tracking) => _tracking.id !== tracking?.id));
            setTrackingId(null);
        },
        onError: (error) => {
             console.error(error);
        },
    });

    const handleDeleteTracking = async () => {
        if(!tracking?.id) return;
        if(!tracking?.userId) return;

        await deleteTracking({ variables: { trackingId: tracking.id } });
    }

  const resolveIcon = (status: ChallengeStatus) => {
    switch (status) {
        case ChallengeStatus.BRONZE:
            return BronzeIcon;
        case ChallengeStatus.SILVER:
            return SilverIcon;
        case ChallengeStatus.GOLD:
            return GoldIcon;
        case ChallengeStatus.PLATINUM:
            return PlatineIcon;
        }
    }

    const resolveBg = (status: ChallengeStatus) => {
        switch (status) {
            case ChallengeStatus.BRONZE:
                return BronzeBg;
            case ChallengeStatus.SILVER:
                return SilverBg;
            case ChallengeStatus.GOLD:
                return GoldBg;
            case ChallengeStatus.PLATINUM:
                return PlatineBg;
        }
    }

    const resolveColor = (status: ChallengeStatus) => {
        switch (status) {
            case ChallengeStatus.BRONZE:
                return '#B38261';
            case ChallengeStatus.SILVER:
                return '#838EA1';
            case ChallengeStatus.GOLD:
                return '#DF781B';
            case ChallengeStatus.PLATINUM:
                return '#9F6FFF';
        }
    }

  useEffect(() => {
    setTracking(user?.trackingEntity?.find((_tracking) => _tracking.id === trackingId) || null);
  }, [user, trackingId])

  if(!tracking) return null;

  return (
        <SModal
            open={trackingId !== null}
            footer={null}
            closeIcon={null}
            width={350}
            className="modalStyle"
            centered
      >
        {validate ? <ValidChallenge tracking={tracking} setTrackingId={setTrackingId}/> : 
        (
            <>
                <FlipContainer onClick={() => setFlipped(!flipped)}>
                    <FlipInner $flipped={flipped} $status={tracking.challengeStatus} >
                        <FrontFace>
                            <SText $fontsize='20px' $fontweight="400" $fontFamily="Bangers" $color={resolveColor(tracking.challengeStatus)}>{tracking.challenge.thematic.name}</SText>
                            <SText $fontsize='18px'>{tracking.challenge.name}</SText>
                            <ThematicIcon src={resolveImg({url: new URL(`@assets/images/`, import.meta.url).href,imageName: tracking.challenge.thematic.asset?? '',})} alt={tracking.challenge.thematic.name} />
                            <img src={resolveBg(tracking.challengeStatus)} alt={tracking.challenge.thematic.name} width="100%" />
                            <SText $fontsize='20px' $fontweight="400" $fontFamily="Bangers" $color={resolveColor(tracking.challengeStatus)}>{i18n._('common.objective')}</SText>
                            <SText $fontsize='14px' $textalign="left">{tracking.challenge.description}</SText>
                        </FrontFace>
                        <BackFace>
                            <SText $fontsize='20px' $fontweight="400" $fontFamily="Bangers" $color={resolveColor(tracking.challengeStatus)}>{i18n._("helptext-title")}</SText>
                            <SText $fontsize='18px'>{tracking.challenge.helpText}</SText>
                        </BackFace>
                    </FlipInner>
                    <SArrow src={Arrow} alt="arrow" $flipped={flipped} />
                    <StatusIcon src={resolveIcon(tracking.challengeStatus)} alt={tracking.challengeStatus} />
                </FlipContainer>
                {tracking.challengeTrackingStatus === ChallengeTrackingStatus.IN_PROGRESS && (
                    <ChallengeActions>
                        <SButton $width="90%" onClick={() => setValidate(true)}>
                            <SText $fontsize='20px'>{i18n._('common.validate-challenge')}</SText>
                        </SButton>
                        <SText $fontsize='18px' $color="#fff" onClick={() => void handleDeleteTracking()}>{i18n._('common.cancel-challenge')}</SText>
                    </ChallengeActions>
                )}
            </>
        )}
      </SModal>
  )
}

export default ChallengeModal;

const SModal = styled(Modal)`
    &&& .ant-modal-content {
        padding: 0;
        background: unset;
        box-shadow: unset;
    }

    &&& .ant-modal-body {
        padding: 0;
        display: flex;
        flex-direction: column;
        gap: 50px;
        justify-content: center;
        align-items: center;
    }    
`

const FlipContainer = styled.div`
    perspective: 1000px; /* Permet de voir l'effet 3D */
`;

const FlipInner = styled.div<{ $flipped: boolean, $status: ChallengeStatus }>`
    width: 100%;
    height: 100%;
    transition: transform 0.6s;
    transform-style: preserve-3d;
    border-radius: 24px;
    padding: 32px 16px;
    display: flex;
    flex-direction: column;
    gap: 5px;
    border: ${({ $status }) => {
        switch ($status) {
            case ChallengeStatus.BRONZE:
                return '2px solid #B38261';
            case ChallengeStatus.SILVER:
                return '2px solid #838EA1';
            case ChallengeStatus.GOLD:
                return '2px solid #DF781B';
            case ChallengeStatus.PLATINUM:
                return '2px solid #9F6FFF';
        }
    }};
    cursor: pointer;
    background: #fff;
    box-shadow: ${({ $status }) => {
        switch ($status) {
            case ChallengeStatus.BRONZE:
                return '0px 2px 0px 0px  #B38261';
            case ChallengeStatus.SILVER:
                return '0px 2px 0px 0px  #838EA1';
            case ChallengeStatus.GOLD:
                return '0px 2px 0px 0px  #DF781B';
            case ChallengeStatus.PLATINUM:
                return '0px 2px 0px 0px  #9F6FFF';
        }
    }};
    transform: ${({ $flipped }) => ($flipped ? "rotateY(180deg)" : "rotateY(0)")};
`;

const FrontFace = styled.div`
    backface-visibility: hidden;
    width: 100%;
    height: 100%;
`;

const BackFace = styled(FrontFace)`
    position: absolute;
    top: 50%;
    left: 50%;  
    transform: translate(-50%, -50%) rotateY(180deg);
    display: flex;
    flex-direction: column;
    gap: 5px;
    justify-content: center;
    align-items: center;
    padding: 5%;

`;

const ThematicIcon = styled.img`
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 40%;
`
const StatusIcon = styled.img`
    position: absolute;
    top: -5%;
    right: -5%;
    width: 56px;
`

const SArrow = styled.img<{ $flipped: boolean }>`
    position: absolute;
    bottom: -4%;
    left: 50%;
    width: 25%;
    transform: ${({ $flipped }) => ($flipped ? "translateX(-50%) scaleX(-1)" : "translateX(-50%) scaleX(1)")};
`

const ChallengeActions = styled.div`
    display: flex;
    flex-direction: column;
    gap: 15px;
    width: 100%;
    align-items: center;

    && > p {
        cursor: pointer;
    }
`