import { Layout } from 'antd';
import { ReactNode } from 'react';
import styled from 'styled-components';
import Header from '~/pages/backoffice/Header';
import NavBar from '~/pages/backoffice/NavBar';

const { Content } = Layout;

type IProps = {
  children: ReactNode;
};

function BackOfficeLayout({ children }: IProps) {
  return (
    <>
      <Layout style={{ minHeight: '100vh', width: 'auto' }}>
        <NavBar />
        <Layout>
          <SContent>
            <Header />
            {children}
          </SContent>
        </Layout>
      </Layout>
    </>
  );
}

export default BackOfficeLayout;

const SContent = styled(Content)`
  padding: 2%;
`;
