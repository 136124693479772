/* eslint-disable */

export const AllTypesProps: Record<string,any> = {
	QuizType: "enum" as const,
	ChallengeDifficulty: "enum" as const,
	DateTime: `scalar.DateTime` as const,
	ChallengeStatus: "enum" as const,
	ChallengeTrackingStatus: "enum" as const,
	AccountStatus: "enum" as const,
	JSON: `scalar.JSON` as const,
	Query:{
		getRole:{

		},
		getMail:{

		},
		loginAsGuest:{

		},
		login:{

		},
		uniqueAuthFromSso:{

		},
		getUser:{

		},
		getFilteredUsers:{
			findManyUserWithFiltersInput:"FindManyUserWithFiltersInput"
		}
	},
	EError: "enum" as const,
	FindManyUserWithFiltersInput:{
		orderBy:"UserOrderByInput",
		filterBy:"UserFilterByInput"
	},
	UserOrderByInput:{
		email:"OrderByEnum",
		firstName:"OrderByEnum",
		lastName:"OrderByEnum",
		lastConnectionAt:"OrderByEnum",
		role:"OrderByEnum"
	},
	OrderByEnum: "enum" as const,
	UserFilterByInput:{

	},
	Mutation:{
		askForResetPassword:{

		},
		resetPassword:{

		},
		verifyEmail:{

		},
		register:{

		},
		askForRegister:{
			registerUserInput:"RegisterUserInput"
		},
		finishRegister:{

		},
		finishRegisterAsGuest:{

		},
		createUser:{
			createUserInput:"CreateUserInput"
		},
		updateUser:{
			updateUserInput:"UpdateUserInput"
		},
		deleteUser:{

		},
		updateTutorEmail:{

		},
		updateSatisfactionQuiz:{
			content:"JSON"
		},
		updateMotionViewed:{

		},
		updateFirstCardDraw:{

		},
		createTracking:{

		},
		deleteTracking:{

		},
		validateTracking:{

		},
		validateQuiz:{

		}
	},
	RegisterUserInput:{

	},
	CreateUserInput:{

	},
	UpdateUserInput:{

	}
}

export const ReturnTypes: Record<string,any> = {
	Health:{
		env:"String",
		time:"String",
		status:"String"
	},
	Answer:{
		id:"Int",
		name:"String",
		text:"String",
		isCorrect:"Boolean",
		quiz:"Quiz",
		quizId:"Float"
	},
	AnswersWithoutCorrect:{
		id:"Int",
		name:"String",
		text:"String",
		quiz:"Quiz",
		quizId:"Float"
	},
	Quiz:{
		id:"Int",
		name:"String",
		question:"String",
		quizType:"QuizType",
		answers:"Answer",
		challenge:"Challenge",
		challengeId:"Float"
	},
	Thematic:{
		id:"Int",
		name:"String",
		asset:"String",
		challenges:"Challenge"
	},
	Challenge:{
		id:"Int",
		name:"String",
		description:"String",
		asset:"String",
		helpText:"String",
		difficulty:"ChallengeDifficulty",
		quizzes:"Quiz",
		trackings:"Tracking",
		thematic:"Thematic",
		thematicId:"Float"
	},
	QuizResponse:{
		id:"Int",
		isCorrect:"Boolean",
		done:"Boolean",
		quizId:"Int",
		challenge:"Challenge",
		challengeId:"Float"
	},
	Tracking:{
		id:"Float",
		createdAt:"DateTime",
		updatedAt:"DateTime",
		user:"Users",
		userId:"Int",
		challengeStatus:"ChallengeStatus",
		challengeTrackingStatus:"ChallengeTrackingStatus",
		challenge:"Challenge",
		quizResponses:"QuizResponse"
	},
	DateTime: `scalar.DateTime` as const,
	Users:{
		id:"Int",
		uuid:"String",
		createdAt:"DateTime",
		updatedAt:"DateTime",
		status:"AccountStatus",
		email:"String",
		password:"String",
		passwordResetToken:"String",
		passwordResetExpiryDate:"DateTime",
		activateAccountToken:"String",
		activateAccountExpiryDate:"DateTime",
		emailVerified:"Boolean",
		verifyEmailToken:"String",
		verifyEmailExpiryDate:"DateTime",
		unlockedDate:"DateTime",
		firstName:"String",
		lastName:"String",
		ecole:"String",
		refreshToken:"String",
		role:"Roles",
		roleId:"Int",
		tutorEmail:"String",
		satisfactionQuiz:"Boolean",
		satisfactionQuizContent:"JSON",
		motionViewed:"Boolean",
		firstCardDraw:"Boolean",
		trackingEntity:"Tracking"
	},
	JSON: `scalar.JSON` as const,
	UserPagination:{
		users:"Users",
		total:"Int"
	},
	Roles:{
		id:"Int",
		uuid:"String",
		createdAt:"DateTime",
		updatedAt:"DateTime",
		name:"String",
		users:"Users"
	},
	UniqueAuth:{
		access_token:"String",
		firstName:"String",
		lastName:"String",
		role:"String",
		email:"String",
		ecole:"String",
		sub:"Int"
	},
	Mail:{
		id:"Int",
		uuid:"String",
		createdAt:"DateTime",
		updatedAt:"DateTime",
		key:"String",
		to:"String",
		data:"String"
	},
	Query:{
		getHealthGql:"Health",
		getRole:"Roles",
		getRoles:"Roles",
		getMail:"Mail",
		getMails:"Mail",
		sendMail:"Boolean",
		loginAsGuest:"AuthType",
		login:"AuthType",
		uniqueAuthFromSso:"UniqueAuth",
		getUser:"Users",
		getUsers:"Users",
		getFilteredUsers:"UserPagination",
		getThematics:"Thematic"
	},
	AuthType:{
		"...on Tokens":"Tokens",
		"...on CustomError":"CustomError"
	},
	Tokens:{
		access_token:"String",
		refresh_token:"String"
	},
	CustomError:{
		error:"EError",
		message:"String"
	},
	Mutation:{
		askForResetPassword:"Boolean",
		resetPassword:"Boolean",
		verifyEmail:"Boolean",
		register:"AuthType",
		askForRegister:"Boolean",
		finishRegister:"Boolean",
		finishRegisterAsGuest:"Boolean",
		refresh:"AuthType",
		logout:"Boolean",
		createUser:"Users",
		updateUser:"Users",
		deleteUser:"Boolean",
		updateTutorEmail:"Users",
		updateSatisfactionQuiz:"Users",
		updateMotionViewed:"Users",
		updateFirstCardDraw:"Users",
		createTracking:"Tracking",
		deleteTracking:"Boolean",
		validateTracking:"Boolean",
		validateQuiz:"Tracking"
	}
}

export const Ops = {
query: "Query" as const,
	mutation: "Mutation" as const
}