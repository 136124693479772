import { ExclamationOutlined } from '@ant-design/icons';
import { useLingui } from '@lingui/react';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import { classic } from '~/themes';
import { SText, StyledButton } from '~/utils/ui/utils';

function Denied() {
  const { i18n } = useLingui();
  const navigate = useNavigate();

  return (
    <Container>
      <ExclamationOutlined
        style={{ color: classic.token?.colorPrimary, fontSize: '50px' }}
      />
      <SText $color={classic.token?.colorPrimary} $fontweight="bold">
        {i18n._('access.denied.message')}
      </SText>
      <StyledButton onClick={() => navigate('/')}>
        {i18n._('common.back')}
      </StyledButton>
    </Container>
  );
}

export default Denied;

const Container = styled.div`
  width: 100vw;
  height: 100vh;
  background: #fff;
  display: flex;
  gap: 25px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;
