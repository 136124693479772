import { ApolloProvider } from '@apollo/client';
import { i18n } from '@lingui/core';
import { I18nProvider } from '@lingui/react';
import { ConfigProvider } from 'antd';
import React from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter, Routes } from 'react-router-dom';
import { client } from '~/helpers/apollo';
import useLanguageStore from '~/helpers/store/locale/locale';
import { catalogs } from '~/locales/catalogs';
import { renderRoutes } from '~/pages/utils/router';
import { classic } from '~/themes/index';
import './index.css';

export function Main() {
  i18n.load(catalogs);
  const { locale } = useLanguageStore();
  i18n.activate(locale);

  return (
    <React.StrictMode>
      <I18nProvider i18n={i18n}>
        <ConfigProvider theme={classic}>
          <ApolloProvider client={client}>
            <BrowserRouter>
              <Routes>{renderRoutes()}</Routes>
            </BrowserRouter>
          </ApolloProvider>
        </ConfigProvider>
      </I18nProvider>
    </React.StrictMode>
  );
}

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
root.render(<Main />);
