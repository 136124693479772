import { MailOutlined } from '@ant-design/icons';
import { useMutation } from '@apollo/client';
import { useLingui } from '@lingui/react';
import { Button, Form, Input, notification } from 'antd';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import { GQL_SEND_RESET_PASSWORD } from '~/gql/auth/auth';
import {  SForm } from '~/pages/authentication/Login';
import { classic } from '~/themes';
import { SH2, SLink, SText } from '~/utils/ui/utils';

function ResetPassword() {
  const [email, setEmail] = useState('');

  const navigate = useNavigate();
  const { i18n } = useLingui();

  const [askForResetPassword] = useMutation(GQL_SEND_RESET_PASSWORD, {
    onCompleted: (data) => {
      if (data.askForResetPassword) {
        notification.success({
          message: i18n._('common.reset'),
          description: i18n._('good.reset.message'),
          placement: 'topRight',
        });
        navigate('/');
      } else {
        notification.error({
          message: i18n._('common.reset'),
          description: i18n._('bad.reset.message'),
          placement: 'topRight',
        });
      }
    },
    onError: () => {
      notification.error({
        message: i18n._('common.reset'),
        description: i18n._('bad.reset.message'),
        placement: 'topRight',
      });
    },
  });

  const resetHandler = async () => {
    if (email) {
      await askForResetPassword({ variables: { email: email } });
    }
  };

  return (
    <Container>
        <SForm initialValues={{ remember: true }} onFinish={resetHandler}>
          <Form.Item>
            <SH2 $color={classic.token?.colorPrimary}>
              {i18n._('password.forgot')}
            </SH2>
            <SText $margin="15px 0">{i18n._('reset.password.message')}</SText>
          </Form.Item>

          <Form.Item
            name="email"
            className="customInput"
            rules={[
              {
                required: true,
                message: i18n._('input.required.message'),
              },
              {
                pattern: new RegExp(/^[\w-\\.]+@([\w-]+\.)+[\w-]{2,4}$/g),
                message: i18n._('input.email.message'),
              },
            ]}
          >
            <Input
              prefix={<MailOutlined className="site-form-item-icon" />}
              placeholder={i18n._('common.email')}
              value={'raphaelmortier@oulook.fr'}
              onChange={(e) => setEmail(e.target.value)}
              size="large"
              type="text"
            />
          </Form.Item>

          <Form.Item>
            <Button
              type="primary"
              htmlType="submit"
              className="login-form-button"
            >
              {i18n._('reset.password')}
            </Button>
          </Form.Item>

          <SLink
            $color="#9291a5"
            $textdecoration="underline"
            onClick={() => navigate('/')}
          >
            {i18n._('login.return-message')}
          </SLink>
        </SForm>
    </Container>
  );
}

export default ResetPassword;

const Container = styled.div`
  position: relative;
  width: 100%;
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;
