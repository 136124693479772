import { GraphQLTypes, InputType, Selector} from "@zeus/index";
import { typedGql } from "@zeus/typedDocumentNode";

const thematicSelector = Selector('Thematic')({
    id: true,
    name: true,
    asset: true,
    challenges: {
        id: true,
        name: true,
        asset: true,
        description: true,
        helpText: true,
        difficulty: true,
        quizzes: {
            id: true,
            question: true,
            quizType: true,
            answers: {
                id: true,
                name: true,
                text: true,
            }
        }
    }
});

export const GQL_GET_THEMATICS = typedGql('query')({
    getThematics: {...thematicSelector},
});

export type StoredThematic = InputType<
  GraphQLTypes['Thematic'],
  typeof thematicSelector
>;
  