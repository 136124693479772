import { ThemeConfig } from 'antd';
// https://ant.design/docs/react/customize-theme
export const classic: ThemeConfig = {
  token: {
    colorPrimary: '#0B446F', //'#188',
    colorFillSecondary: '#0B446F4D', //'#10888880',
    colorText: '#000',
    colorTextSecondary: '#4A4A4A',
    colorFillAlter: '#f0f0f0',
    colorIcon: '#0B446F', //'#188',
    colorIconHover: '#0B446F', //'#188',
    colorLink: '#0B446F', // '#188',
    colorError: '#E7340E ',
    colorTextDisabled: '#0B446F',
  },
  components: {
    Input: {
      colorBgContainer: '#F4A58B',
      colorText: '#000',
      colorTextPlaceholder: '#000',
      addonBg: '#F4A58B',
      activeBg: '#F4A58B',
      hoverBg: '#F4A58B',
      hoverBorderColor: '#000',
      errorActiveShadow: '#F4A58B',
      activeBorderColor: '#000',
      activeShadow: '#F4A58B',
      colorBorder: '#000',
      inputFontSize: 14,
      inputFontSizeLG: 14,
      inputFontSizeSM: 14,
    },
    Modal: {
      colorText: '#4A4A4A',
    },
    Drawer: {
      colorText: '#000000',
    },
    Table: {
      colorText: '#000000',
    },
  },
};
