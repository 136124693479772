import { useLingui } from "@lingui/react";
import { ChallengeStatus } from "@zeus/index";
import { useState } from "react";
import styled from "styled-components";
import {  GQL_VALIDATE_TRACKING, StoredTracking } from "~/gql/tracking/tracking";
import { useTracking } from "~/helpers/store/tracking";
import { SText } from "~/utils/ui/utils";
import BronzeIcon from '@assets/images/icon-bronze.png'
import SilverIcon from '@assets/images/icon-argent.png'
import GoldIcon from '@assets/images/icon-or.png'
import PlatineIcon from '@assets/images/icon-platine.png'
import { SButton } from "~/pages/authentication/Login";
import { useMutation } from "@apollo/client";
import { authClient } from "~/helpers/apollo";

interface IProps {
    tracking: StoredTracking | null;
    setTrackingId: (trackingId: number | null) => void;
}

function ValidChallenge ({ tracking, setTrackingId }: IProps) {
  const { trackings, setTrackings } = useTracking();
  const [value, setValue] = useState<string>('');
  const { i18n } = useLingui();

  const [validateTracking] = useMutation(GQL_VALIDATE_TRACKING, {
        client: authClient,
        onCompleted: () => {
            setTrackings(trackings.filter((_tracking) => _tracking.id !== tracking?.id));
            setTrackingId(null);
        },
        onError: (error) => {
             console.error(error);
        },
    });

    const handleValidateTracking = async () => {
        if(!tracking?.id) return;
        if(value.length < 20) return;

        await validateTracking({ variables: { trackingId: tracking.id, text: value } });
    }

  const resolveIcon = (status: ChallengeStatus) => {
    switch (status) {
        case ChallengeStatus.BRONZE:
            return BronzeIcon;
        case ChallengeStatus.SILVER:
            return SilverIcon;
        case ChallengeStatus.GOLD:
            return GoldIcon;
        case ChallengeStatus.PLATINUM:
            return PlatineIcon;
        }
    }

  if(!tracking) return null;

  return (
    <>
        <Card $status={tracking.challengeStatus} >
            <SText $fontsize='18px' $texttransform="uppercase">{i18n._('validate-challenge.explain')}</SText>
            <STextArea placeholder="Zone de texte libre" onChange={(e) => setValue(e.target.value)}/>
        </Card>
        <StatusIcon src={resolveIcon(tracking.challengeStatus)} alt={tracking.challengeStatus} />
        <SButton $width="90%" $disabled={value.length < 20 ? true : false} onClick={handleValidateTracking}>
            <SText $fontsize='20px'>{i18n._('common.validate-challenge')}</SText>
        </SButton>
    </>
  )
}

export default ValidChallenge;

const Card = styled.div<{ $status: ChallengeStatus }>`
    width: 100%;
    height: 100%;
    transition: transform 0.6s;
    transform-style: preserve-3d;
    border-radius: 24px;
    padding: 32px 16px;
    display: flex;
    flex-direction: column;
    gap: 5px;
    border: ${({ $status }) => {
        switch ($status) {
            case ChallengeStatus.BRONZE:
                return '2px solid #B38261';
            case ChallengeStatus.SILVER:
                return '2px solid #838EA1';
            case ChallengeStatus.GOLD:
                return '2px solid #DF781B';
            case ChallengeStatus.PLATINUM:
                return '2px solid #9F6FFF';
        }
    }};
    cursor: pointer;
    background: #fff;
    box-shadow: ${({ $status }) => {
        switch ($status) {
            case ChallengeStatus.BRONZE:
                return '0px 2px 0px 0px  #B38261';
            case ChallengeStatus.SILVER:
                return '0px 2px 0px 0px  #838EA1';
            case ChallengeStatus.GOLD:
                return '0px 2px 0px 0px  #DF781B';
            case ChallengeStatus.PLATINUM:
                return '0px 2px 0px 0px  #9F6FFF';
        }
    }};
`;

const StatusIcon = styled.img`
    position: absolute;
    top: -5%;
    right: -5%;
    width: 56px;
`

const STextArea = styled.textarea`
    border-radius: 8px;
    background: #E9E9E9;
    height: 349px;
    width: 100%;
    margin-top: 25px;
    padding: 5%;
    resize: none;
    color: #000;
`
